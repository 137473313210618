<template>
    <b-row
            class="mt-5"
            :class="!right && 'flex-row-reverse'">
        <!-- Empty space -->
        <b-col
                class="d-none d-xl-block p-0"
                xl="1"></b-col>

        <!-- Emblem -->
        <b-col
                class="d-flex justify-content-center align-items-center"
                cols="12"
                md="3"
                xl="2">
            <emblem
                    class="d-none d-md-flex"
                    :class="right ? 'slide-left' : 'slide-right'"
                    :bgColor="right ? '#355989' : '#888'"
                    color="#fff"
                    style="width: 70%; height: 90%"
                    v-in-viewport='{ margin: "-50px 0px" }'>
                {{ title }}
            </emblem>

            <h2
                    class="d-md-none mobile-h2 text-center font-weight-bold text-white">
                {{ title }}
            </h2>
        </b-col>

        <!-- Empty space -->
        <b-col
                class="d-none d-sm-block d-md-none p-0"
                sm="1"></b-col>

        <!-- Content -->
        <b-col
                class="content d-flex flex-column justify-content-center"
                :class="!right && 'align-items-end'"
                cols="9"
                sm="8"
                md="5">
            <slide-group
                    :from="right ? 'right' : 'left'">
                <slot></slot>
            </slide-group>
        </b-col>

        <!-- Image (optional) -->
        <b-col
                class="p-0"
                cols="3"
                md="4">
            <div
                    class="img d-flex align-items-center"
                    :style="{
                        left: !right && '0',
                        right: right && '0',
                        transform: `translateX(${right ? '' : '-'}15px)`,
                        maskImage: `linear-gradient(to ${right ? 'left' : 'right'}, rgba(0,0,0,1), rgba(0,0,0,.25) 60%, rgba(0,0,0,0))`
                    }"
                    v-if="img">
                <responsive-img
                        class="fade-in"
                        :img="img"
                        :alt="alt"
                        ratio="4:3"
                        sizes="(min-width: 1024px) 60vw, (min-width: 768px) 75vw, 100vw"
                        v-in-viewport='{ margin: "-40px 0px" }'
                        lazy></responsive-img>
            </div>
        </b-col>
    </b-row>
</template>

<script>
  import SlideGroup from '@/components/elements/SlideGroup'
  import Emblem from '@/components/elements/Emblem'
  import ResponsiveImg from '@/components/elements/ResponsiveImg'

  export default {
    name: 'ContentRow',

    components: { SlideGroup, Emblem, ResponsiveImg },

    props: {
      title: String,
      img: String,
      alt: String,
      right: Boolean
    }
  }
</script>

<style scoped>
    .row {
        min-height: calc(25vw - 30px);
    }

    .content {
        z-index: 44;
        background-color: rgba(255,255,255,.5);
    }

    .img {
        position: absolute;
        width: 100vw;
        height: 100%;
    }

    .content, .img {
        min-height: 200px;
    }

    .mobile-h2 {
        width: 100%;
        padding: .5rem 0;
        background: var(--var-estsuelcon-primary);
        border-radius: 10px;
        font-size: 1.25rem
    }

    @media (min-width: 768px) {
        .img {
            width: 75vw;
        }
    }

    @media (min-width: 1024px) {
        .content {
            background-color: transparent;
        }

        .img {
            width: 60vw;
        }
    }
</style>