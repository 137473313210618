var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-group position-absolute w-100"},[_c(_vm.title ? 'h1' : 'span',{directives:[{name:"in-viewport",rawName:"v-in-viewport"}],tag:"div",staticClass:"text position-absolute text-center",class:{
                'slide-left': _vm.right && _vm.right[_vm.ratio],
                'slide-right': _vm.left && _vm.left[_vm.ratio],
                h1: _vm.title
            },style:({
                top: _vm.top && _vm.top[_vm.ratio] && ("calc(" + (_vm.top[_vm.ratio]) + " + " + _vm.offsetTop + ")"),
                bottom: _vm.bottom && _vm.bottom[_vm.ratio] && ("calc(" + (_vm.bottom[_vm.ratio]) + " + " + _vm.offsetBottom + ")"),
                left: _vm.left && _vm.left[_vm.ratio],
                right: _vm.right && _vm.right[_vm.ratio],
                transitionDelay: _vm.delay
            }),domProps:{"innerHTML":_vm._s(_vm.text)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }