<template>
    <page
            img="proyectos"
            alt="Proyectos">
        <responsive-text
                text="Proyectos"
                :left="{
                    916: '10%',
                    34: '10%',
                    43: '10%',
                    169: '10%'
                }"
                :top="{
                    916: '10%',
                    34: '10%',
                    43: '10%',
                    169: '10%'
                }"
                title/>
    </page>
</template>

<script>
  export default {
    name: 'Proyectos'
  }
</script>

<style scoped>

</style>