<template>
    <page
            img="consultoria"
            alt="Consultoría"
            itemscope
            itemtype="http://schema.org/Service">

        <meta itemprop="serviceType" content="Consultoría y construcción"/>
        <link itemprop="provider" href="#estsuelcon"/>

        <responsive-text
                text="Consultoría y<br/>construcción"
                :right="{
                    916: '2%',
                    34: '2%',
                    43: '4%',
                    169: '4%'
                }"
                :bottom="{
                    916: '4%',
                    34: '4%',
                    43: '4%',
                    169: '4%'
                }"
                title/>

        <content-row
                title="Consultoría técnica"
                img="consultoria2"
                alt="Consultoría"
                right>
            <p>Estudios Geológicos Geotécnicos.</p>
            <p>Diseños arquitectónicos, estructurales, hidráulicos, etc.</p>
            <p>Estudios de estabilidad de taludes.</p>
            <p>Evaluación y métodos de explotación de canteras.</p>
            <p>Diseño de presas de relave, tierra y enrocado.</p>
            <p>Diseño de obras menores.</p>
            <p>Diseños integrales de carreteras.</p>
            <p>Fiscalización de Proyectos.</p>
            <p>Otros.</p>
        </content-row>
    </page>
</template>

<script>
  import Page from '@/components/elements/Page'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import ContentRow from '@/components/elements/ContentRow'

  export default {
    name: 'Consultancy',

    components: { Page, ResponsiveText, ContentRow }
  }
</script>