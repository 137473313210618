var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"img":"control_de_calidad","alt":"Control de calidad","itemscope":"","itemtype":"http://schema.org/Service"}},[_c('meta',{attrs:{"itemprop":"serviceType","content":"Control de calidad"}}),_c('link',{attrs:{"itemprop":"provider","href":"#estsuelcon"}}),_c('responsive-text',{attrs:{"text":"Control de calidad","left":{
                43: '12%',
                169: '4%'
            },"right":{
                916: '0',
                34: '5%'
            },"top":{
                916: '2%',
                34: '2%',
                43: '33%',
                169: '20%'
            },"title":""}}),_c('content-row',{attrs:{"title":"Ensayos de Mecánica de Suelos y Materiales","img":"control_de_calidad2","alt":"Control de calidad","right":""}},[_c('p',[_vm._v("Densidad de campo.")]),_c('p',[_vm._v("Ensayo de permeabilidad.")]),_c('p',[_vm._v("Ensayo de placa de carga.")]),_c('p',[_vm._v("Extracción de muestras de probetas o testigos cilíndricos de concreto.")]),_c('p',[_vm._v("Ensayos de penetración (SPT, Cono Peck).")]),_c('p',[_vm._v("Muestreo de suelos y traslado al laboratorio.")])]),_c('hr',{staticClass:"hr"}),_c('content-row',{attrs:{"title":"Supervisión en la construcción","img":"control_de_calidad3","alt":"Control de calidad"}},[_c('p',[_vm._v("Control de calidad en movimiento de tierras.")]),_c('p',[_vm._v("Control de calidad en obras de concreto.")]),_c('p',[_vm._v("Control de calidad en colocación de mezclas asfálticas.")]),_c('p',[_vm._v("Control y ensayos de laboratorio de mecánica de suelos para la construcción en general.")]),_c('p',[_vm._v("Control de calidad en vías y puentes.")]),_c('p',[_vm._v("Contamos con capacidad de instalación de laboratorio en obra.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }