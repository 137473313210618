var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"img":"geofisica","alt":"Geofísica","itemscope":"","itemtype":"http://schema.org/Service"}},[_c('meta',{attrs:{"itemprop":"serviceType","content":"Estudios geofísicos"}}),_c('link',{attrs:{"itemprop":"provider","href":"#estsuelcon"}}),_c('responsive-text',{attrs:{"text":"Geofísica","left":{
                916: '2%',
                34: '6%',
                43: '12%',
                169: '12%'
            },"top":{
                916: '12%',
                34: '16%',
                43: '10%',
                169: '18%'
            },"title":""}}),_c('responsive-text',{attrs:{"text":"Sísmica de Refracción","left":{
                916: '1%',
                34: '2%',
                43: '4%',
                169: '3%'
            },"top":{
                916: '35%',
                34: '40%',
                43: '25%',
                169: '49%'
            },"delay":".2s"}}),_c('responsive-text',{attrs:{"text":"Resistividad Eléctrica","left":{
                916: '1%',
                34: '2%',
                43: '4%',
                169: '3%'
            },"top":{
                916: '51%',
                34: '60%',
                43: '38%',
                169: '68%'
            },"delay":".4s"}}),_c('content-row',{attrs:{"title":"Métodos Eléctricos","img":"geofisica3","alt":"Geofísica"}},[_c('p',[_vm._v("Tomografía Eléctrica (TE).")]),_c('p',[_vm._v("Sondaje Eléctrico Vertical (SEV).")]),_c('p',[_vm._v("Calicata eléctrica (CE).")])]),_c('hr',{staticClass:"hr"}),_c('content-row',{attrs:{"title":"Métodos Sísmicos","img":"geofisica2","alt":"Geofísica","right":""}},[_c('p',[_vm._v("Ensayo de sísmica de refracción.")]),_c('p',[_vm._v("Ensayos MASW.")]),_c('p',[_vm._v("Ensayos SPAC.")]),_c('p',[_vm._v("Ensayos REMI.")]),_c('p',[_vm._v("Ensayos Nakamura.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }