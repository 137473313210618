<script>
  export default {
    name: 'SlideGroup',

    render (h) {
      this.$slots.default.forEach((node, i) => {
        node.data = node.data || {}

        node.data.directives = (node.data.directives || []).concat([{
          name: 'in-viewport',
          rawName: 'v-in-viewport'
        }])

        node.data.staticClass = (node.data.staticClass ? `${node.data.staticClass} ` : '') + 'slide-' + this.from

        node.data.staticStyle = Object.assign(node.data.staticStyle || {}, {
          transitionDelay: `${i * 80}ms`
        })
      })

      return h('div', this.$slots.default)
    },

    props: {
      from: String
    }
  }
</script>