<template>
    <picture
            class="picture w-100 h-100">
        <!-- webp -->
        <source
                :srcset="srcset('16:9', 1, 1)"
                :data-srcset="srcset('16:9', 0, 1)"
                :sizes="sizes"
                :media="!ratio && '(min-aspect-ratio: 16/10)'"
                type="image/webp"
                v-if="!ratio || ratio === '16:9'"/>
        <source
                :srcset="srcset('4:3',1 ,1)"
                :data-srcset="srcset('4:3', 0, 1)"
                :sizes="sizes"
                :media="!ratio && '(min-aspect-ratio: 1/1)'"
                type="image/webp"
                v-if="!ratio || ratio === '4:3'"/>
        <source
                :srcset="srcset('3:4', 1, 1)"
                :data-srcset="srcset('3:4', 0, 1)"
                :sizes="sizes"
                :media="!ratio && '(max-aspect-ratio: 10/16)'"
                type="image/webp"
                v-if="!ratio || ratio === '3:4'"/>
        <source
                :srcset="srcset('9:16', 1, 1)"
                :data-srcset="srcset('9:16', 0, 1)"
                :sizes="sizes"
                :media="!ratio && '(max-aspect-ratio: 99/100)'"
                type="image/webp"
                v-if="!ratio || ratio === '9:16'"/>

        <!-- jpg -->
        <source
                :srcset="srcset('16:9')"
                :data-srcset="srcset('16:9', 0)"
                :sizes="sizes"
                :media="!ratio && '(min-aspect-ratio: 16/10)'"
                type="image/jpeg"
                v-if="!ratio || ratio === '16:9'"/>
        <source
                :srcset="srcset('4:3')"
                :data-srcset="srcset('4:3', 0)"
                :sizes="sizes"
                :media="!ratio && '(min-aspect-ratio: 1/1)'"
                type="image/jpeg"
                v-if="!ratio || ratio === '4:3'"/>
        <source
                :srcset="srcset('3:4')"
                :data-srcset="srcset('3:4', 0)"
                :sizes="sizes"
                :media="!ratio && '(max-aspect-ratio: 10/16)'"
                type="image/jpeg"
                v-if="!ratio || ratio === '3:4'"/>
        <source
                :srcset="srcset('9:16')"
                :data-srcset="srcset('9:16', 0)"
                :sizes="sizes"
                :media="!ratio && '(max-aspect-ratio: 99/100)'"
                type="image/jpeg"
                v-if="!ratio || ratio === '9:16'"/>

        <!-- default -->
        <img :alt="alt"
             :src="src()"
             :data-src="src(ratio, 0)"
             :data-srcset="srcset(ratio, 0)"
             :sizes="sizes"
             class="img w-100 h-100"
             v-b-visible.once="lazyLoad"/>
    </picture>
</template>

<script>
  export default {
    name: 'ResponsiveImg',

    props: {
      img: String,
      alt: String,
      ratio: {
        type: String,
        validator: (value) => {
          return ['9:16', '3:4', '4:3', '16:9'].includes(value)
        }
      },
      sizes: String,
      lazy: Boolean
    },

    data () {
      return {
        base: `assets/images/thumbnails/${this.img}`,
        thumbnails: {
          sizes: [
            3 / 2,
            1,
            3 / 4,
            1 / 2,
            1 / 3
          ].sort().reverse(),
          base: {
            '16:9': 1920,
            '4:3': 2048,
            '3:4': 1536,
            '9:16': 1080
          }
        }
      }
    },

    methods: {
      /**
       * Gets src for image
       * @param ratio String
       * @param lazy Boolean
       * @param webp Boolean
       * @returns {string|any}
       */
      src (ratio = this.ratio, lazy = true, webp = false) {
        if (this.lazy && lazy) {
          return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
        }
        const r = ratio ? ratio.replace(':', '-') : '16-9'
        if (webp) {
          return require(`@/assets/images/webp/${this.img}_${r}.webp`)
        } else {
          return require(`@/assets/images/${this.img}_${r}.jpg`)
        }
      },

      /**
       * Gets srcset for image
       * @param ratio String
       * @param lazy Boolean
       * @param webp Boolean
       * @returns {string|any}
       */
      srcset (ratio = this.ratio, lazy = true, webp = false) {
        if (this.lazy && lazy) {
          return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
        }

        const r = ratio ? ratio.replace(':', '-') : '16-9'
        const base = webp ? this.base.replace(/thumbnails/, 'webp/$&') : this.base
        const ext = webp ? 'webp' : 'jpg'
        const srcset = []

        this.thumbnails.sizes.forEach((size) => {
          const w = Math.floor(this.thumbnails.base[ratio || '16:9'] * size)
          if (!srcset.length) {
            srcset.push(this.src(ratio, lazy, webp) + ` ${w + 1}w`)
          }
          srcset.push(require(`@/${base}_${r}_${w}.${ext}`) + ` ${Math.floor(w * 1)}w`)
        })
        return srcset.join(', ')
      },

      /**
       * Lazyloads image on entering viewport
       * @param visible Boolean
       */
      lazyLoad (visible) {
        if (this.lazy && visible) {
          this.$el.getElementsByTagName('source').forEach(source => source.srcset = source.getAttribute('data-srcset'))
          this.$el.getElementsByTagName('img').forEach((img) => {
            img.srcset = img.getAttribute('data-srcset')
            img.src = img.getAttribute('data-src')
          })
        }
      }
    }
  }
</script>

<style scoped>
    .img {
        object-fit: cover;
        max-height: 100vh
    }
</style>