<template>
    <div
            class="page position-relative mb-5">
        <div
                class="banner">
            <responsive-img
                    :img="img"
                    :alt="alt"
                    ratio="16:9"></responsive-img>
        </div>

        <b-container
            :fluid="true">
            <slot></slot>
        </b-container>
    </div>
</template>

<script>
  import ResponsiveImg from '@/components/elements/ResponsiveImg'

  export default {
    name: 'Page',

    components: { ResponsiveImg },

    props: {
      img: String,
      alt: String
    }
  }
</script>

<style scoped>
    .banner,
    .page >>> .text-group {
        top: 0;
        left: 0;
        height: 56vw;
        max-height: 60vh
    }

    .page >>> .hr {
        width: 42%;
        border: 1px dashed #999;
        border-radius: 1px;
        margin: 2rem auto
    }
</style>