<template>
    <div
            class="text-group position-absolute w-100">
        <div
                :is="title ? 'h1' : 'span'"
                class="text position-absolute text-center"
                :class="{
                    'slide-left': right && right[ratio],
                    'slide-right': left && left[ratio],
                    h1: title
                }"
                :style="{
                    top: top && top[ratio] && `calc(${top[ratio]} + ${offsetTop})`,
                    bottom: bottom && bottom[ratio] && `calc(${bottom[ratio]} + ${offsetBottom})`,
                    left: left && left[ratio],
                    right: right && right[ratio],
                    transitionDelay: delay
                }"
                v-html="text"
                v-in-viewport>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ResponsiveText',

    props: {
      text: String,
      top: Object,
      offsetTop: {
        type: String,
        default: '0px'
      },
      bottom: Object,
      offsetBottom: {
        type: String,
        default: '0px'
      },
      left: Object,
      right: Object,
      delay: {
        type: String,
        default: 'inherit'
      },
      title: Boolean
    },

    data() {
      return {
        ratio: '169'
      }
    },

    mounted() {
      this.resize()
    },

    created() {
      window.addEventListener('resize', this.resize)
    },

    destroyed() {
      window.removeEventListener('resize', this.resize)
    },

    methods: {
      /**
       * Calc new ratio on window size change
       */
      resize() {
        const r = window.innerWidth / window.innerHeight

        if (r > 16 / 10) {
          this.ratio = '169'
        } else if (r >= 1) {
          this.ratio = '43'
        } else if (r < 10 / 16) {
          this.ratio = '916'
        } else {
          this.ratio = '34'
        }
      }
    }
  }
</script>

<style scoped>
    .text {
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        text-transform: uppercase;
        background: rgba(0, 0, 0, .6);
        padding: 0 20px;
        border-radius: 20px;
    }

    .h1 {
        margin: 0;
        font-size: 2rem;
        font-weight: bold;
        text-transform: uppercase
    }

    @media (min-width: 576px) {
        .text {
            font-size: 1.5rem
        }

        .h1 {
            font-size: 2.5rem;
        }
    }

    @media (min-width: 768px) {
        .text {
            font-size: 1.65rem
        }

        .h1 {
            font-size: 2.65rem;
        }
    }

    @media (min-width: 1280px) {
        .text {
            font-size: 2.5rem
        }

        .h1 {
            font-size: 4rem;
        }
    }
</style>