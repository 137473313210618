<template>
    <page

            img="control_de_calidad"
            alt="Control de calidad"
            itemscope
            itemtype="http://schema.org/Service">

        <meta itemprop="serviceType" content="Control de calidad"/>
        <link itemprop="provider" href="#estsuelcon"/>

        <responsive-text
                text="Control de calidad"
                :left="{
                    43: '12%',
                    169: '4%'
                }"
                :right="{
                    916: '0',
                    34: '5%'
                }"
                :top="{
                    916: '2%',
                    34: '2%',
                    43: '33%',
                    169: '20%'
                }"
                title/>

        <content-row
                title="Ensayos de Mecánica de Suelos y Materiales"
                img="control_de_calidad2"
                alt="Control de calidad"
                right>
            <p>Densidad de campo.</p>
            <p>Ensayo de permeabilidad.</p>
            <p>Ensayo de placa de carga.</p>
            <p>Extracción de muestras de probetas o testigos cilíndricos de concreto.</p>
            <p>Ensayos de penetración (SPT, Cono Peck).</p>
            <p>Muestreo de suelos y traslado al laboratorio.</p>
        </content-row>

        <hr class="hr"/>

        <content-row
                title="Supervisión en la construcción"
                img="control_de_calidad3"
                alt="Control de calidad">
            <p>Control de calidad en movimiento de tierras.</p>
            <p>Control de calidad en obras de concreto.</p>
            <p>Control de calidad en colocación de mezclas asfálticas.</p>
            <p>Control y ensayos de laboratorio de mecánica de suelos para la construcción en general.</p>
            <p>Control de calidad en vías y puentes.</p>
            <p>Contamos con capacidad de instalación de laboratorio en obra.</p>
        </content-row>
    </page>
</template>

<script>
  import Page from '@/components/elements/Page'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import ContentRow from '@/components/elements/ContentRow'

  export default {
    name: 'Geophysics',

    components: { Page, ResponsiveText, ContentRow }
  }
</script>