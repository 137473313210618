var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"img":"perforaciones","alt":"Perforaciones","itemscope":"","itemtype":"http://schema.org/Service"}},[_c('meta',{attrs:{"itemprop":"serviceType","content":"Perforaciones"}}),_c('link',{attrs:{"itemprop":"provider","href":"#estsuelcon"}}),_c('responsive-text',{attrs:{"text":"Perforaciones","right":{
                916: '6%',
                34: '6%',
                43: '6%',
                169: '6%'
            },"top":{
                916: '2%',
                34: '4%',
                43: '10%',
                169: '2%'
            },"title":""}}),_c('responsive-text',{attrs:{"text":"Destructivas","right":{
                916: '1%',
                34: '4%',
                43: '4%',
                169: '1%'
            },"top":{
                916: '24%',
                34: '28%',
                43: '25%',
                169: '32%'
            },"delay":".2s"}}),_c('responsive-text',{attrs:{"text":"Diamantinas","right":{
                916: '1%',
                34: '4%',
                43: '4%',
                169: '1%'
            },"top":{
                916: '42%',
                34: '48%',
                43: '36%',
                169: '51%'
            },"delay":".4s"}}),_c('content-row',{attrs:{"title":"Sondajes Geotécnicos","img":"perforaciones2","alt":"Perforaciones","right":""}},[_c('p',[_vm._v("Sondaje diamantino en suelos y roca con/sin recuperación de muestras.")]),_c('p',[_vm._v("Sondaje con martillo trepano y tricono.")]),_c('p',[_vm._v("Sondaje con espiral para anclajes tipo Soil Nails.")]),_c('p',[_vm._v("Ensayos SPT.")]),_c('p',[_vm._v("Ensayos de permeabilidad (Lugeon, Lefranc).")]),_c('p',[_vm._v("Instalación de piezómetros.")]),_c('p',[_vm._v("Instalación de inclinómetros.")]),_c('p',[_vm._v("Monitoreo e interpretación de la instrumentación geotécnica.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }