var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"img":"proyectos","alt":"Proyectos"}},[_c('responsive-text',{attrs:{"text":"Proyectos","left":{
                916: '10%',
                34: '10%',
                43: '10%',
                169: '10%'
            },"top":{
                916: '10%',
                34: '10%',
                43: '10%',
                169: '10%'
            },"title":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }