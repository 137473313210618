<template>
    <page
            img="geofisica"
            alt="Geofísica"
            itemscope
            itemtype="http://schema.org/Service">

        <meta itemprop="serviceType" content="Estudios geofísicos"/>
        <link itemprop="provider" href="#estsuelcon"/>

        <responsive-text
                text="Geofísica"
                :left="{
                    916: '2%',
                    34: '6%',
                    43: '12%',
                    169: '12%'
                }"
                :top="{
                    916: '12%',
                    34: '16%',
                    43: '10%',
                    169: '18%'
                }"
                title/>
        <responsive-text
                text="Sísmica de Refracción"
                :left="{
                    916: '1%',
                    34: '2%',
                    43: '4%',
                    169: '3%'
                }"
                :top="{
                    916: '35%',
                    34: '40%',
                    43: '25%',
                    169: '49%'
                }"
                delay=".2s"/>
        <responsive-text
                text="Resistividad Eléctrica"
                :left="{
                    916: '1%',
                    34: '2%',
                    43: '4%',
                    169: '3%'
                }"
                :top="{
                    916: '51%',
                    34: '60%',
                    43: '38%',
                    169: '68%'
                }"
                delay=".4s"/>

        <content-row
                title="Métodos Eléctricos"
                img="geofisica3"
                alt="Geofísica">
            <p>Tomografía Eléctrica (TE).</p>
            <p>Sondaje Eléctrico Vertical (SEV).</p>
            <p>Calicata eléctrica (CE).</p>
        </content-row>

        <hr class="hr"/>

        <content-row
                title="Métodos Sísmicos"
                img="geofisica2"
                alt="Geofísica"
                right>
            <p>Ensayo de sísmica de refracción.</p>
            <p>Ensayos MASW.</p>
            <p>Ensayos SPAC.</p>
            <p>Ensayos REMI.</p>
            <p>Ensayos Nakamura.</p>
        </content-row>
    </page>
</template>

<script>
  import Page from '@/components/elements/Page'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import ContentRow from '@/components/elements/ContentRow'

  export default {
    name: 'Geophysics',

    components: { Page, ResponsiveText, ContentRow }
  }
</script>