<template>
    <page
            img="contacto"
            alt="Contacto"
            itemscope
            itemtype="https://schema.org/ContactPage">
        <b-overlay
                :show="isLoading"
                class="{
                    'd-none': !isLoading
                }"
                :class="{ 'position-absolute': isLoading }"
                style="left: 0; top:0; right: 0; bottom: 0;z-index: 9999"
                fixed></b-overlay>

        <responsive-text
                text="Contáctanos"
                :left="{
                    916: '8%',
                    34: '10%',
                    43: '10%',
                    169: '10%'
                }"
                :top="{
                    916: '12%',
                    34: '40%',
                    43: '36%',
                    169: '30%'
                }"
                title/>

        <b-container
                class="p-0"
                fluid="lg">
            <b-form
                    ref="form"
                    @submit.prevent="sendMail"
                    action="#contacto"
                    method="POST">

                <!-- Name -->
                <b-input-group
                        size="lg mt-3"
                        style="transition-delay: .1s"
                        class="slide-bottom"
                        v-in-viewport.once>
                    <template v-slot:prepend>
                        <b-input-group-text
                                is="label"
                                class="input-group-text"
                                for="name"
                                :class="{
                                    'success': validName
                                }"
                                @click="$refs.name.focus()">
                            Nombre
                        </b-input-group-text>
                    </template>
                    <b-form-input
                            :state="validName"
                            @keyup.enter="$refs.company.focus()"
                            autofocus="autofocus"
                            id="name"
                            name="name"
                            ref="name"
                            required
                            size="lg"
                            type="text"
                            v-model="name"/>
                </b-input-group>

                <!-- Company -->
                <b-input-group
                        size="lg mt-3"
                        style="transition-delay: .2s"
                        class="slide-bottom"
                        v-in-viewport.once>
                    <template v-slot:prepend>
                        <b-input-group-text
                                is="label"
                                class="input-group-text"
                                for="company"
                                :class="{
                                    'success': validCompany
                                }"
                                @click="$refs.company.focus()">Empresa
                        </b-input-group-text>
                    </template>
                    <b-form-input
                            :state="validCompany"
                            @keyup.enter="$refs.email.focus()"
                            id="company"
                            name="company"
                            ref="company"
                            required
                            size="lg"
                            type="text"
                            v-model="company"/>
                </b-input-group>

                <!-- Email -->
                <b-input-group
                        size="lg mt-3"
                        style="transition-delay: .35s"
                        class="slide-bottom"
                        v-in-viewport.once>
                    <template v-slot:prepend>
                        <b-input-group-text
                                is="label"
                                class="input-group-text"
                                for="email"
                                :class="{
                                    'success': validEmail
                                }"
                                @click="$refs.email.focus()">Email
                        </b-input-group-text>
                    </template>
                    <b-form-input
                            :state="validEmail"
                            @keyup.enter="$refs.text.focus()"
                            id="email"
                            name="email"
                            pattern="(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|&quot;(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*&quot;)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])"
                            ref="email"
                            required
                            size="lg"
                            type="email"
                            v-model="email"/>
                </b-input-group>

                <!-- Text -->
                <b-input-group
                        size="lg mt-3"
                        style="transition-delay: .5s"
                        class="slide-bottom"
                        v-in-viewport.once>
                    <template v-slot:prepend>
                        <b-input-group-text
                                is="label"
                                class="input-group-text"
                                for="text"
                                :class="{
                                    'success': validText
                                }"
                                @click="$refs.text.focus()">Consulta
                        </b-input-group-text>
                    </template>
                    <b-form-textarea
                            :state="validText"
                            id="text"
                            name="text"
                            placeholder="¿En qué podemos ayudar?"
                            ref="text"
                            required
                            rows="5"
                            v-model="text"/>
                </b-input-group>

                <b-button
                        ref="submit"
                        :disabled="!validForm"
                        :variant="validForm && 'success'"
                        size="lg mt-3"
                        style="transition-delay: .65s"
                        type="submit"
                        class="fade-in"
                        v-b-visible="inViewport">
                    <b-icon icon="envelope"/>
                    Enviar
                </b-button>
            </b-form>

            <!-- Map main -->
            <div
                    class="map mt-5 text-center fade-in"
                    style="transition-delay: .3s"
                    v-in-viewport='{ margin: "-50px 0px" }'>
                <h2>
                    Visítanos
                    <span
                            style="white-space: nowrap">
                        en nuestra sede
                    </span>
                    en Loja
                </h2>
                <iframe
                        ref="map"
                        class="d-block ml-auto mr-auto mb-5 w-75 border-0"
                        style="max-height: 40vh"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.380337956009!2d-79.22113668524034!3d-3.941724797133156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91cb480ada94b321%3A0xf5764d58c5135735!2sEstsuelcon%20Cia.%20Ltda.!5e0!3m2!1sen!2sec!4v1589068447165!5m2!1ses!2sec"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                        title="Mapa de la sede principal en Loja"
                        v-b-visible="mapHeight"/>
            </div>

            <!-- Map branch -->
            <div
                    class="map mt-5 text-center fade-in"
                    style="transition-delay: .3s"
                    v-in-viewport='{ margin: "-50px 0px" }'>
                <h2>
                    Visítanos
                    <span
                            style="white-space: nowrap">
                        en nuestra sucursal
                    </span>
                    en Zamora Chinchipe
                </h2>
                <iframe
                        ref="map2"
                        class="d-block ml-auto mr-auto mb-5 w-75 border-0"
                        style="max-height: 40vh"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d995.3073470058688!2d-78.64978047077182!3d-3.760179699829027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91cb8205882ee29f%3A0x8104ff24059181ea!2sAmazonas%2C%20Los%20Encuentros!5e0!3m2!1sen!2sec!4v1589685268245!5m2!1ses!2sec"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                        title="Mapa de la surcursal en Zamora Chinchipe"
                        v-b-visible="mapHeight2"/>
            </div>
        </b-container>
    </page>
</template>

<script>
  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3'
  import Page from '@/components/elements/Page'
  import ResponsiveText from '@/components/elements/ResponsiveText'

  Vue.use(VueReCaptcha, {
    siteKey: '6LdYL_EUAAAAAAHqN_yqsEpnIgawFB0JsowNumEt',
    loaderOptions: {
      renderParameters: {
        hl: 'es-419'
      }
    }
  })

  export default {
    name: 'Contacto',

    components: { Page, ResponsiveText },

    data () {
      return {
        isLoading: false,
        name: '',
        company: '',
        email: '',
        text: ''
      }
    },

    mounted () {
      this.name = this.$refs.name.$el.value = sessionStorage.name || ''
      this.company = this.$refs.company.$el.value = sessionStorage.company || ''
      this.email = this.$refs.email.$el.value = sessionStorage.email || ''
      this.text = this.$refs.text.$el.value = sessionStorage.text || ''

      if (this.$recaptchaInstance) {
        this.$recaptchaInstance.showBadge()
      }
    },

    beforeDestroy () {
      this.$recaptchaInstance.hideBadge()
    },

    computed: {
      validName () {
        if (this.$refs.name) {
          sessionStorage.name = this.name
        }
        return (this.name && this.$refs.name.validity.valid) || null
      },
      validCompany () {
        if (this.$refs.company) {
          sessionStorage.company = this.company
        }
        return (this.company && this.$refs.company.validity.valid) || null
      },
      validEmail () {
        if (this.$refs.email) {
          sessionStorage.email = this.email
        }
        return (this.email && this.$refs.email.validity.valid) || null
      },
      validText () {
        if (this.$refs.text) {
          sessionStorage.text = this.text
        }
        return (this.text && this.$refs.text.validity.valid) || null
      },
      validForm () {
        return (this.validName && this.validCompany && this.validEmail && this.validText)
      }
    },

    methods: {
      /**
       * Custom visible trigger for google map
       * @param visible Boolean
       */
      mapHeight (visible) {
        if (visible) {
          this.$refs.map.height = Math.floor(document.forms[0].clientWidth * .75)
        }
      },

      /**
       * Custom visible trigger for google map
       * @param visible Boolean
       */
      mapHeight2 (visible) {
        if (visible) {
          this.$refs.map2.height = Math.floor(document.forms[0].clientWidth * .75)
        }
      },

      /**
       * Custom visible trigger for submit button
       * @param visible Boolean
       */
      inViewport (visible) {
        if (visible) {
          this.$refs.submit.classList.add('in-viewport')
          this.$refs.submit.style.setProperty('opacity', 1, 'important')
        }
      },

      async sendMail () {
        this.isLoading = true

        // Get recaptcha token
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('homepage')

        const form = this.$refs.form
        const data = new FormData(form)
        data.append('token', token)

        fetch('/php/contact.php', {
          method: form.method,
          body: data
        })
          .then(response => response.json())
          .then((response) => {
            const message = response.success ? 'Su correo se ha enviado con éxito.' : 'Su correo no se ha enviado.'

            if (response.success) {
              this.name = ''
              this.company = ''
              this.email = ''
              this.text = ''
            }

            this.$bvToast.toast(message, {
              title: response.success ? '¡Muchas gracias!' : 'Error!',
              variant: response.success ? 'success' : 'danger',
              autoHideDelay: 12000
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
</script>

<style scoped>
    .input-group-text {
        justify-content: center;
        align-items: start;
        width: 6.67rem;
    }

    .input-group-text.success {
        color: #155724;
        background-color: rgba(212, 237, 218, 0.85)
    }

    .input-fade-enter-active {
        transition: opacity .5s, transform .5s;
    }

    .input-fade-enter {
        opacity: 0 !important;
        transform: translateY(1.25vh);
    }

    .form-control {
        background-size: 3rem !important;
        background-position: right 0 center !important;
        transition: background-size .2s ease, background-position .2s ease
    }

    .was-validated .form-control:valid, .form-control.is-valid {
        background-size: 1.5rem !important;
        background-position: right .75rem center !important
    }

    .btn.disabled, .btn:disabled {
        cursor: not-allowed;
    }

    .btn-success {
        transition-delay: unset !important;
        animation: bounce .2s ease;
    }

    @keyframes bounce {
        0% {
            transform: scale(1)
        }
        50% {
            transform: scale(1.2)
        }
        100% {
            transform: scale(1)
        }
    }

    .visit {
        color: var(--var-estsuelcon-primary)
    }
</style>