<template>
    <page
            class="about text-justify"
            img="nosotros"
            alt="Nosotros"
            itemscope
            itemtype="https://schema.org/AboutPage">
        <responsive-text
                text="Nosotros"
                :left="{
                    916: '5%',
                    34: '8%',
                    43: '8%',
                    169: '12%'
                }"
                :top="{
                    916: '50%',
                    34: '50%',
                    43: '50%',
                    169: '50%'
                }"
                title/>

        <b-row class="mt-5">
            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>

            <!-- History -->
            <b-col
                    class="d-flex flex-column justify-content-center"
                    cols="12"
                    sm="7"
                    md="8"
                    lg="7">
                <div
                        class="fade-in"
                        style="transition-delay: .3s"
                        v-in-viewport='{ margin: "-30px 0px" }'>
                    <h2 class="h2">Historia</h2>
                    <p>
                        ESTSUELCON CÍA. LTDA. es una empresa dedicada al Estudio de Suelos, Laboratorio Construcción y
                        Consultoría Integral; desde sus orígenes considera que la calidad, experiencia, innovación
                        tecnológica y la capacitación de su personal son y serán su carta de presentación, reflejándola
                        en sus servicios.
                    </p>

                    <responsive-img
                            class="slide-right d-sm-none"
                            style="transition-delay: .3s"
                            img="historia"
                            alt="Historia"
                            ratio="3:4"
                            sizes="(min-width: 576px) 1px, calc(100vw - 30px)"
                            v-in-viewport></responsive-img>

                    <p>La empresa tiene sus orígenes por los años 1990 como un pequeño Laboratorio de Suelos particular
                        creada por su fundador Ing. Ovidio Castillo docente en esos tiempos en la Universidad Particular
                        de Loja en Cátedra de Mecánica de Suelos y Materiales de Construcción, luego a través de los
                        años y de la capacitación continua de su personal y la inversión en nuevas tecnologías fue
                        ampliando sus servicios al área de la Geotecnia, Geología, Geofísica y Diseño integral de Obras
                        Civiles en General, hasta que en el 2009 se estableció finalmente como Compañía Limitada.
                    </p>
                </div>
            </b-col>

            <!-- Image -->
            <b-col
                    class="d-none d-sm-flex align-items-center"
                    cols="0"
                    sm="5"
                    md="4"
                    lg="3">
                <responsive-img
                        class="slide-right"
                        style="transition-delay: .3s"
                        img="historia"
                        alt="Historia"
                        ratio="3:4"
                        sizes="(min-width: 992px) calc(25vw - 30px), (min-width: 768px) calc(33.33vw - 30px), (min-width: 576px) calc(41.67vw - 30px), 1px"
                        v-in-viewport></responsive-img>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>

            <!-- Mission -->
            <b-col
                    class="d-flex flex-column justify-content-center"
                    cols="12"
                    lg="8">
                <div
                        class="fade-in"
                        style="transition-delay: .3s"
                        v-in-viewport='{ margin: "-40px 0px" }'>
                    <h2 class="h2">Misión</h2>
                    <p>
                        Ser la empresa líder en consultoría civil, geología y construcción de obras civiles.
                        Satisfaciendo las necesidades de la comunidad ecuatoriana tanto en el ámbito privado como
                        público. Generar valor económico para nuestros clientes, accionistas, proveedores y
                        funcionarios.
                    </p>
                </div>
            </b-col>

            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>
        </b-row>

        <b-row class="mt-3">
            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>

            <!-- Vision -->
            <b-col
                    class="d-flex flex-column justify-content-center"
                    cols="12"
                    lg="8">
                <div
                        class="fade-in"
                        style="transition-delay: .3s"
                        v-in-viewport='{ margin: "-40px 0px" }'>
                    <h2 class="h2">Visión</h2>
                    <p>
                        Ser la compañía integradora entre la ciencia y la experiencia, líderes en servicios de calidad,
                        transmisión de conocimientos a las nuevas generaciones y promoción del cuidado al medio
                        ambiente.
                    </p>
                </div>
            </b-col>

            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>
        </b-row>

        <b-row class="mt-3">
            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>

            <!-- Team -->
            <b-col
                    class="d-flex flex-column justify-content-center"
                    cols="12"
                    lg="8">
                <div
                        class="fade-in"
                        style="transition-delay: .3s"
                        v-in-viewport='{ margin: "-40px 0px" }'>
                    <h2 class="h2">Equipo</h2>
                    <p>
                        Nuestro equipo profesional técnico con experiencia y altamente calificados garantizan la calidad
                        en todos nuestros servicios.
                    </p>
                </div>
            </b-col>

            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"></b-col>
        </b-row>

        <!-- Team picture -->
        <b-row>
            <b-col
                    class="d-none d-lg-block"
                    lg="1"/>

            <b-col
                    cols="12"
                    lg="10">
                <b-aspect
                        aspect="16:9"
                        class="fade-in mt-5"
                        style="width: 100%; margin: 0 auto -15% !important;"
                        v-in-viewport>
                    <responsive-img
                            img="equipo"
                            alt="Equipo"
                            class="team-image"
                            ratio="16:9"
                            sizes="(min-width: 992px) calc(83.33vw - 30px), calc(100vw - 30px)"
                            lazy/>
                </b-aspect>
            </b-col>
        </b-row>

        <!-- Strategic partners -->
        <b-row class="mt-3">
            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"/>

            <b-col
                    class="d-flex flex-column justify-content-center"
                    cols="12"
                    lg="8">
                <div
                        class="fade-in"
                        style="transition-delay: .3s"
                        v-in-viewport='{ margin: "-40px 0px" }'>
                    <h2 class="h2">Socios estratégicos</h2>
                </div>
            </b-col>

            <!-- Empty space -->
            <b-col
                    class="d-none d-lg-block"
                    lg="2"/>
        </b-row>

        <!-- ESTSUELCON -->
        <partner
                title=""
                :activities="[
                    {
                        name: 'Geotecnia',
                        contacts: [
                            {
                                name: 'Ing. Ovidio Castillo',
                                email: 'ovidio.c@estsuelcon.ec',
                                mobile: '+593-980253180'
                            },
                            {
                                name: 'Ing. Claudel Guerrero',
                                email: 'claudel.g@estsuelcon.ec',
                                mobile: '+593-987458943'
                            }
                        ]
                    },
                    {
                        name: 'Ingeniería Vial',
                        contacts: [
                            {
                                name: 'Ing. Diego Castillo',
                                email: 'diego.c@estsuelcon.ec',
                                mobile: '+593-999692335'
                            },
                            {
                                name: 'Ing. Oscar Cango',
                                email: 'olcango@hotmail.com',
                                mobile: '+593-984880314'
                            }
                        ]
                    },
                    {
                        name: 'Diseño Geométrico De Vías',
                        contacts: [
                            {
                                name: 'Ing. Pablo Paccha',
                                email: 'ppaccha@yahoo.com',
                                mobile: '+593-969581298'
                            }
                        ]
                    },
                    {
                        name: 'Infraestructura Bin y Civil 3d',
                        contacts: [
                            {
                                name: 'Ing. Marco Guaya'
                            }
                        ]
                    },
                    {
                        name: 'Hidrogeología',
                        contacts: [
                            {
                                name: 'Ing. Yetzabel Flores',
                                email: 'yegerarda@hotmail.com',
                                mobile: '+593-999817316'
                            },
                            {
                                name: 'Ing. Milena González',
                                email: 'mi_189@hotmail.com',
                                mobile: '+593-967816566'
                            }
                        ]
                    },
                    {
                        name: 'Riesgos Geológicos',
                        contacts: [
                            {
                                name: 'Ing. Tamara Briceño',
                                email: 'tamycbs@gmail.com',
                                mobile: '+593-995943952'
                            }
                        ]
                    }
                ]"/>

        <hr class="hr"/>

        <!-- GRISS -->
        <partner
                title="GRISS-UTPL"
                :img="require('@/assets/logos/griss.png')"
                url="http://ingenieriasismica.utpl.edu.ec"
                :activities="[
                    {
                        name: 'Estructural',
                        contacts: [
                            {
                                name: 'Ing. Edwin Duque',
                                email: 'epduque@utpl.edu.ec',
                                mobile: '+593-992713568'
                            },
                            {
                                name: 'Ing. José Hurtado',
                                email: 'jchurtadoh@gmail.com',
                                mobile: '+593-983004066'
                            }
                        ]
                    }
                ]"/>

        <hr class="hr"/>

        <!-- ACUATICA -->
        <partner
                title="Acuática"
                :activities="[
                    {
                        name: 'Hidrosanitario y ' +
                              '<span class=&quot;text-nowrap&quot;>Recursos Hídricos</span>',
                        contacts: [
                            {
                                name: 'Ing. Patricio León',
                                email: 'pdleon_86@hotmail.com',
                                mobile: '+593-94457694'
                            },
                            {
                                name: 'Ing. Fernando Jaramillo',
                                email: 'fejaidro@gmail.com',
                                mobile: '+593-988945233'
                            }
                        ]
                    },
                    {
                        name: 'Hidrología e Hidráulica',
                        contacts: [
                            {
                                name: 'Ing. Rafael González González',
                                email: 're_gonzalez@hotmail.es',
                                mobile: '+593-994945181'
                            },
                            {
                                name: 'Ing. Rafael González Carrión',
                                email: 'rafaelgc17@gmail.com',
                                mobile: '+593-995284687'
                            }
                        ]
                    }
                ]"
                right/>

        <hr class="hr"/>

        <!-- INCOELECTRIC -->
        <partner
                title="Incoelectric"
                :img="require('@/assets/logos/incoelectric.png')"
                url="https://incoelectric.com"
                :activities="[
                    {
                        name: 'Eléctrico Y Electrónico',
                        contacts: [
                            {
                                name: 'Ing. Ramiro Neptalí Jiménez',
                                email: 'incoelectric@gmail.com',
                                mobile: '+593-985826480'
                            }
                        ]
                    },
                    {
                        name: 'Riesgos Sísmicos',
                        contacts: [
                            {
                                name: 'Ing. Adriana Ayala',
                                email: 'adrisayala@gmail.com',
                                mobile: '+593-988606110'
                            }
                        ]
                    }
                ]"/>

        <hr class="hr"/>

        <!-- Consultoría y Proyectos -->
        <partner
                title="Consultoría y Proyectos"
                :img="require('@/assets/logos/consultoria-y-proyectos.png')"
                url="https://www.facebook.com/Consultor%C3%ADa-y-Proyectos-104868954573072/"
                :activities="[
                    {
                        name: '<span class=&quot;text-nowrap&quot;>Salud Ocupacional</span>, ' +
                              '<span class=&quot;text-nowrap&quot;>Seguridad Industrial</span>, ' +
                              '<span class=&quot;text-nowrap&quot;>Medio Ambiente</span>, ' +
                              '<span class=&quot;text-nowrap&quot;>Control de Calidad y Proyectos</span>',
                        contacts: [
                            {
                                name: 'Ing. Yadira Solórzano',
                                email: 'yadira172@hotmail.com',
                                mobile: '+593-999132722'
                            }
                        ]
                    }
                ]"
                right/>

        <hr class="hr"/>

        <!-- Elemental -->
        <partner
                title="Elemental"
                :img="require('@/assets/logos/elemental.jpg')"
                url="https://www.facebook.com/pages/category/Local-Business/ELEMENTAL-Topograf%C3%ADa-Geodesia-790314331002966"
                :activities="[
                    {
                        name: 'Topografía y Geodesia',
                        contacts: [
                            {
                                name: 'Ing. Felipe Jiménez',
                                email: 'elemental_loja@hotmail.com',
                                mobile: '+593-994797059'
                            },
                            {
                                name: 'Ing. Yandry Lalagui',
                                email: 'yandri92_jar@hotmail.com',
                                mobile: '+593-985091266'
                            }
                        ]
                    }
                ]"/>

        <hr class="hr"/>

        <!-- SurHábitat -->
        <partner
                title="SurHábitat"
                :img="require('@/assets/logos/surhabitat.png')"
                url="https://www.facebook.com/pages/category/Real-Estate-Agent/Estudio-de-Arquitectura-SurH%C3%A1bitat-182959931752831"
                :activities="[
                    {
                        name: 'Arquitectura',
                        contacts: [
                            {
                                name: 'Arq. Ángel Montaño',
                                email: 'arqangelrafael@hotmail.com',
                                mobile: '+593-986827002'
                            },
                            {
                                name: 'Arq. José Morocho',
                                email: 'josemorochoarquitecto@hotmail.com',
                                mobile: '+593-994414678'
                            }
                        ]
                    }
                ]"
                right/>

        <hr class="hr"/>

        <!-- Gema -->
        <partner
                title="Gema"
                :img="require('@/assets/logos/gema.png')"
                url="https://ec.linkedin.com/in/cesar-morocho-5b8957181"
                :activities="[
                    {
                        name: 'Minería',
                        contacts: [
                            {
                                name: 'Ing. César Morocho',
                                email: 'ts.camp29@gmail.com',
                                mobile: '+593-981687463'
                            }
                        ]
                    }
                ]"/>

        <hr class="hr"/>

        <!-- Identidades con Impacto -->
        <partner
                title="Identidades con Impacto"
                :img="require('@/assets/logos/identidades-con-impacto.png')"
                url="https://identidadesconimpa.wixsite.com/identidadesconimpa-1"
                :activities="[
                    {
                        name: 'Emprendimiento',
                        contacts: [
                            {
                                name: 'Ing. Gaby González',
                                email: 'gaby.2694@hotmail.com',
                                mobile: '+593-995956428'
                            }
                        ]
                    }
                ]"
                right/>

        <hr class="hr"/>

        <!-- Fungeomine -->
        <partner
                title="Fungeomine"
                :img="require('@/assets/logos/fungeomine.jpg')"
                url="https://www.fungeomine.org"
                :activities="[
                    {
                        name: 'Investigación Geológica Minera',
                        contacts: [
                            {
                                name: 'Ing. Edgar Pillajo',
                                email: 'pillajo2001@yahoo.es',
                                mobile: '+593-994327648'
                            }
                        ]
                    }
                ]"/>
    </page>
</template>

<script>
  import Page from '@/components/elements/Page'
  import ResponsiveImg from '@/components/elements/ResponsiveImg'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import Partner from '@/components/elements/Partner'

  export default {
    name: 'About',

    components: { Page, ResponsiveImg, ResponsiveText, Partner }
  }
</script>

<style scoped>
    .h2 {
        color: var(--var-estsuelcon-primary);
        margin: 2rem
    }

    .about >>> .team-image > img {
        clip-path: inset(30% 0 0 0);
        margin-top: -15%
    }

    .about .hr {
        margin: 4rem auto
    }
</style>