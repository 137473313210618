<template>
    <b-row
            class="row flex-row-reverse flex-lg-row"
            :class="!right && 'flex-lg-row-reverse'"
            itemscope
            itemtype="https://schema.org/Organization">
        <!-- Empty space -->
        <b-col
                class="d-none d-lg-block"
                lg="2"/>

        <!-- Specialty -->
        <b-col
                :class="`slide-${!right ? 'right' : 'left'}`"
                style="transition-delay: .3s"
                cols="6"
                lg="4"
                v-in-viewport='{ margin: "-40px 0px" }'>

            <!-- Contacts -->
            <b-row>
                <b-col
                        v-for="(contact, i) in contacts"
                        :key="i"
                        cols="6"
                        itemprop="employee"
                        itemscope
                        itemtype="https://schema.org/Person">

                    <!-- Name -->
                    <span
                            v-if="contact.name"
                            class="mt-1 mb-0"
                            itemprop="name">
                            {{ contact.name }}
                        </span>

                    <!-- Email -->
                    <b-link
                            v-if="contact.email"
                            class="link"
                            :href="`mailto:${contact.email}`">
                        <b-icon-envelope
                                class="icon"/>
                        <span
                                itemprop="email">
                                            {{ contact.email }}
                                        </span>
                    </b-link>

                    <!-- Phone number -->
                    <b-link
                            v-if="contact.phone"
                            class="link"
                            :href="`tel:${contact.phone}`"
                            itemprop="telephone"
                            :content="contact.phone">
                        <b-icon-house-door
                                class="icon"/>
                        {{ contact.phone }}
                    </b-link>

                    <!-- Mobile number -->
                    <b-link
                            v-if="contact.mobile"
                            class="link"
                            :href="`https://api.whatsapp.com/send?phone=${contact.mobile.replace(/[+-]/g, '')}`"
                            itemprop="telephone"
                            :content="contact.mobile">
                        <whatsapp
                                class="icon"
                                style="fill: currentColor"/>
                        {{ contact.mobile }}
                    </b-link>
                </b-col>
            </b-row>
        </b-col>

        <!-- Partner name or logo -->
        <b-col
                :class="`slide-${right ? 'right' : 'left'}`"
                style="transition-delay: .3s"
                cols="6"
                lg="4"
                v-in-viewport='{ margin: "-40px 0px" }'>

            <div
                    :is="url ? 'b-link' : 'div'"
                    class="link d-flex flex-column justify-content-center align-items-center h-100 mt-2"
                    :href="url && url"
                    :target="url && '_blank'">

                <!-- Logo -->
                <b-img-lazy
                        v-if="img"
                        class="img"
                        :src="img"
                        :alt="title"
                        itemprop="logo"/>
                <meta
                        v-if="img"
                        itemprop="name"
                        :content="title"/>

                <!-- Alternative title -->
                <h3
                        v-if="!img"
                        class="h3">
                    {{ title }}
                </h3>

                <!-- Url -->
                <span
                        v-if="url"
                        :content="url"
                        itemprop="url">
                    {{ url.replace(/^https?:\/\/([^\/]+).*$/, '$1') }}
                </span>
            </div>
        </b-col>

        <!-- Empty space -->
        <b-col
                class="d-none d-lg-block"
                lg="2"/>

    </b-row>
</template>

<script>
  import whatsapp from '@/assets/icons/whatsapp.svg'

  export default {
    name: 'ContentRow',

    components: { whatsapp },

    props: {
      title: String,
      img: String,
      url: String,
      activities: Array,
      right: Boolean
    }
  }
</script>

<style scoped>
    .img {
        max-width: 90%;
        max-height: 5rem;
    }

    .link {
        display: inline-flex;
        align-items: center;
        color: var(--var-estsuelcon-primary)
    }

    .icon {
        width: 1rem;
        height: 1rem;
        margin: 0 .5rem
    }

    .h3 {
        margin: .5rem;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--var-estsuelcon-primary)
    }

    .h4 {
        font-size: 1.15rem;
        font-weight: bold;
    }
</style>