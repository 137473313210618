<template>
    <b-button
            class="more-info position-fixed ml-auto mr-auto slide-bottom text-center btn-estsuelcon"
            :to="current ? current.route : '/'"
            :aria-label="current ? current.label : 'Estsuelcon'"
            variant="transparent"
            v-in-viewport>
        <h2
                class="h2 m-0 font-weight-bold text-uppercase">
            Más información
            <span class="seo">
                acerca de {{ current ? current.label : 'Estsuelcon' }}
            </span>
        </h2>
    </b-button>
</template>

<script>
  export default {
    name: 'MoreInfo',

    props: {
      current: Object
    },

    mounted() {
      setTimeout(() => {
        this.$el.classList.add('in-viewport')
      }, 100)

      setTimeout(() => {
        this.$el.style.setProperty('transition-delay', 'unset')
      }, 1500)
    },

    beforeDestroy() {
      this.$el.classList.remove('in-viewport')
    }
  }
</script>

<style scoped>
    .more-info {
        left:0;
        bottom: 2rem;
        right: 0;
        width: 340px;
        max-width: 100%;
        padding: 1rem;
        border-radius: 20px;
        z-index: 9;
        transition-delay: .5s
    }

    .more-info > .h2 {
        font-size: 1.25rem
    }

    .seo {
        font-size: 0;
    }
</style>