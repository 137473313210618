<template>
    <div
            class="position-relative align-items-center">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 150"
                class="svg position-absolute w-100 h-100"
                :style="{ fill: bgColor }">
            <path d="M0 0 L100 0 L100 110 L50 150 L0 110 Z"/>
        </svg>

        <h2
                class="font-weight-bold text-center w-100 p-2"
                style="font-size: 1.15rem; margin-top: -15%"
                :style="{ color: color }">
            <slot/>
        </h2>

        <div
                style="height: 15%"/>
    </div>
</template>

<script>
  export default {
    name: 'Emblem',

    props: {
      color: String,
      bgColor: String
    }
  }
</script>

<style scoped>
    .svg {
        z-index: -1;
    }
</style>