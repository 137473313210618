<template>
    <page
            img="perforaciones"
            alt="Perforaciones"
            itemscope
            itemtype="http://schema.org/Service">

        <meta itemprop="serviceType" content="Perforaciones"/>
        <link itemprop="provider" href="#estsuelcon"/>

        <responsive-text
                text="Perforaciones"
                :right="{
                    916: '6%',
                    34: '6%',
                    43: '6%',
                    169: '6%'
                }"
                :top="{
                    916: '2%',
                    34: '4%',
                    43: '10%',
                    169: '2%'
                }"
                title/>
        <responsive-text
                text="Destructivas"
                :right="{
                    916: '1%',
                    34: '4%',
                    43: '4%',
                    169: '1%'
                }"
                :top="{
                    916: '24%',
                    34: '28%',
                    43: '25%',
                    169: '32%'
                }"
                delay=".2s"/>
        <responsive-text
                text="Diamantinas"
                :right="{
                    916: '1%',
                    34: '4%',
                    43: '4%',
                    169: '1%'
                }"
                :top="{
                    916: '42%',
                    34: '48%',
                    43: '36%',
                    169: '51%'
                }"
                delay=".4s"/>

        <content-row
                title="Sondajes Geotécnicos"
                img="perforaciones2"
                alt="Perforaciones"
                right>
            <p>Sondaje diamantino en suelos y roca con/sin recuperación de muestras.</p>
            <p>Sondaje con martillo trepano y tricono.</p>
            <p>Sondaje con espiral para anclajes tipo Soil Nails.</p>
            <p>Ensayos SPT.</p>
            <p>Ensayos de permeabilidad (Lugeon, Lefranc).</p>
            <p>Instalación de piezómetros.</p>
            <p>Instalación de inclinómetros.</p>
            <p>Monitoreo e interpretación de la instrumentación geotécnica.</p>
        </content-row>
    </page>
</template>

<script>
  import Page from '@/components/elements/Page'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import ContentRow from '@/components/elements/ContentRow'

  export default {
    name: 'Drills',

    components: { Page, ResponsiveText, ContentRow }
  }
</script>