<template>
    <b-row
            class="flex-row"
            :class="right && 'flex-xl-row-reverse'"
            style="margin-bottom: -2.5rem"
            :itemscope="title"
            :itemtype="title && 'https://schema.org/Organization'">
        <!-- Empty space -->
        <b-col
                class="d-none d-md-block"
                md="1"
                :xl="title ? '2' : null"/>

        <!-- Partner name or logo -->
        <b-col
                v-if="title"
                class="pb-3"
                :class="`slide-${right ? 'right' : 'left'}`"
                style="transition-delay: .3s"
                cols="12"
                md="10"
                xl="3"
                v-in-viewport='{ margin: "-40px 0px" }'>

            <div
                    :is="url ? 'b-link' : 'div'"
                    class="link d-flex flex-column justify-content-center align-items-center h-100 mt-2 mb-5"
                    :href="url && url"
                    :target="url && '_blank'">

                <!-- Logo -->
                <b-img-lazy
                        v-if="img"
                        class="img"
                        :src="img"
                        :alt="title"
                        itemprop="logo"/>
                <meta
                        v-if="img"
                        itemprop="name"
                        :content="title"/>

                <!-- Alternative title -->
                <h3
                        v-if="!img"
                        class="h3">
                    {{ title }}
                </h3>

                <!-- Url -->
                <span
                        v-if="url"
                        :content="url"
                        itemprop="url">
                    {{ url.replace(/^https?:\/\/([^\/]+).*$/, '$1') }}
                </span>
            </div>
        </b-col>

        <!-- Empty space -->
        <b-col
                v-if="title"
                class="d-none d-md-block d-xl-none"
                md="1"/>

        <!-- Empty space -->
        <b-col
                v-if="title"
                class="d-none d-md-block d-xl-none"
                md="1"/>

        <!-- Partner -->
        <b-col
                cols="12"
                md="10"
                :xl="title ? 6 : 10">

            <!-- Activities -->
            <b-row
                    class="justify-content-center">
                <!-- Activity -->
                <b-col
                        v-for="(activity, i) in activities"
                        :key="i"
                        :class="`slide-${title && !right || !title && i % 2 ? 'right' : 'left'}`"
                        style="transition-delay: .3s"
                        cols="12"
                        sm="6"
                        :xl="title ? '12' : null"
                        v-in-viewport='{ margin: "-40px 0px" }'>

                    <!-- Activity name -->
                    <h4
                            v-if="activity.name"
                            class="h4 text-center"
                            v-html="activity.name"/>

                    <b-row
                            class="justify-content-center">
                        <!-- Contacts -->
                        <b-col
                                v-for="(contact, i2) in activity.contacts"
                                :key="i2"
                                class="mb-5"
                                style="overflow-x: hidden"
                                cols="12"
                                :lg="title ? '6' : null"
                                :xl="!title ? '6' : null"
                                :itemprop="title ? 'employee' : null"
                                itemscope
                                itemtype="https://schema.org/Person">

                            <link
                                    v-if="!title"
                                    itemprop="worksFor"
                                    href="#estsuelcon"/>

                            <meta
                                    itemprop="knowsAbout"
                                    :content="activity.name.replace(/(<([^>]+)>)/ig, '')"/>

                            <!-- Name -->
                            <div
                                    v-if="contact.name"
                                    class="mt-1 mb-0"
                                    itemprop="name">
                                {{ contact.name }}
                            </div>

                            <!-- Email -->
                            <b-link
                                    v-if="contact.email"
                                    class="link"
                                    :href="`mailto:${contact.email}`">
                                <b-icon-envelope
                                        class="icon"/>
                                <span
                                        itemprop="email">
                                            {{ contact.email }}
                                        </span>
                            </b-link>

                            <!-- Phone number -->
                            <b-link
                                    v-if="contact.phone"
                                    class="link"
                                    :href="`tel:${contact.phone}`"
                                    itemprop="telephone"
                                    :content="contact.phone">
                                <b-icon-house-door
                                        class="icon"/>
                                {{ contact.phone }}
                            </b-link>

                            <!-- Mobile number -->
                            <b-link
                                    v-if="contact.mobile"
                                    class="link"
                                    :href="`https://api.whatsapp.com/send?phone=${contact.mobile.replace(/[+-]/g, '')}`"
                                    target="_blank"
                                    itemprop="telephone"
                                    :content="contact.mobile">
                                <whatsapp
                                        class="icon"
                                        style="fill: currentColor"/>
                                {{ contact.mobile }}
                            </b-link>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>

        <!-- Empty space -->
        <b-col
                class="d-none d-md-block"
                md="1"/>

    </b-row>
</template>

<script>
  import whatsapp from '@/assets/icons/whatsapp.svg'

  export default {
    name: 'ContentRow',

    components: { whatsapp },

    props: {
      title: String,
      img: String,
      url: String,
      activities: Array,
      right: Boolean
    }
  }
</script>

<style scoped>
    .img {
        max-width: 90%;
        max-height: 6rem;
    }

    .link {
        display: flex;
        align-items: center;
        color: var(--var-estsuelcon-primary)
    }

    .icon {
        width: 1rem;
        height: 1rem;
        margin: 0 .5rem
    }

    .h3 {
        margin: .5rem;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--var-estsuelcon-primary)
    }

    .h4 {
        font-size: 1.15rem;
        font-weight: bold;
    }
</style>