<template>
    <page
            img="laboratorio"
            alt="Laboratorio"
            itemscope
            itemtype="http://schema.org/Service">

        <meta itemprop="serviceType" content="Laboratorio"/>
        <link itemprop="provider" href="#estsuelcon"/>

        <responsive-text
                text="Laboratorio"
                :right="{
                    916: '2%',
                    34: '2%',
                    43: '10%',
                    169: '6%'
                }"
                :top="{
                    43: '40%',
                    169: '30%'
                }"
                :bottom="{
                    34: '20%',
                    916: '24%'
                }"
                title/>
        <responsive-text
                text="Suelos y Materiales"
                :right="{
                    916: '2%',
                    34: '4%',
                    43: '2%',
                    169: '2%'
                }"
                :top="{
                    43: '58%',
                    169: '60%'
                }"
                :bottom="{
                    34: 'calc(20% - 1.8em)',
                    916: '8%',
                }"
                delay=".2s"/>

        <content-row
                title="Ensayos Estándares en Suelos"
                img="laboratorio3"
                alt="Laboratorio"
                right>
            <p>Caracterización física de los suelos (análisis granulométrico por tamizado y sedimentación, límites de
                Atterberg, contenido de humedad).</p>
            <p>Peso específico de los sólidos.</p>
            <p>Peso unitario volumétrico (densidad natural).</p>
            <p>Ensayos de compactación (proctor estándar y modificado).</p>
            <p>CBR (California Bearing Ratio).</p>
        </content-row>

        <hr class="hr"/>

        <content-row
                title="Ensayos Especiales en Suelos"
                img="laboratorio5"
                alt="Laboratorio">
            <p>Compresión no confinada.</p>
            <p>Resistencia al corte directo y residual.</p>
            <p>Consolidación unidimensional.</p>
            <p>Compresión triaxial tipo UU, CU y CD.</p>
            <p>Permeabilidad.</p>
        </content-row>

        <hr class="hr"/>

        <content-row
                title="Ensayos de Mecánica de Rocas"
                img="laboratorio4"
                alt="Laboratorio"
                right>
            <p>Ensayos para determinar las propiedades físicas de la roca (densidad seca y saturada, contenido de humedad, porosidad y absorción).</p>
            <p>Ensayo de carga puntual.</p>
            <p>Ensayo de compresión uniaxial.</p>
            <p>Resistencia a la tracción indirecta.</p>
            <p>Ensayo Till Test.</p>
        </content-row>

        <hr class="hr"/>

        <content-row
                title="Ensayos de Agregados"
                img="laboratorio2"
                alt="Laboratorio">
            <p>Análisis granulométrico de agregados finos, gruesos y global.</p>
            <p>Terrones de arcillas y partículas desmenuzables.</p>
            <p>Durabilidad de agregados finos y gruesos.</p>
            <p>Peso específico y absorción del agregado fino y grueso.</p>
            <p>Desgate por abrasión (Los ángeles).</p>
            <p>Resistencia a los sulfatos.</p>
            <p>Impurezas orgánicas.</p>
            <p>Partículas chatas y alargadas.</p>
            <p>Porcentaje de caras fracturadas.</p>
            <p>Determinación de material más fino.</p>
        </content-row>
    </page>
</template>

<script>
  import Page from '@/components/elements/Page'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import ContentRow from '@/components/elements/ContentRow'

  export default {
    name: 'Laboratorio',

    components: { Page, ResponsiveText, ContentRow }
  }
</script>