<template>
    <div
            class="home">
        <more-info
                :current="current"/>

        <b-carousel
                id="carousel"
                class="h-100"
                :interval="6000"
                v-model="slide"
                @sliding-end="resetAnimations"
                controls
                no-hover-pause>

            <!-- Laboratorio -->
            <b-carousel-slide>
                <template v-slot:img>
                    <responsive-text
                            text="Laboratorio"
                            :right="{
                                916: '1%',
                                34: '14%',
                                43: '1%',
                                169: '3%'
                            }"
                            :top="{
                                916: '55%',
                                34: '28%',
                                43: '33%',
                                169: '32%'
                            }"
                            title/>
                    <responsive-text
                            text="Suelos y Materiales"
                            :right="{
                                916: '3%',
                                34: '4%',
                                43: '3%',
                                169: '6%'
                            }"
                            :top="{
                                916: '63%',
                                34: '40%',
                                43: '44%',
                                169: '52%'
                            }"
                            delay=".2s"/>
                    <responsive-img
                            alt="Laboratorio"
                            img="laboratorio"></responsive-img>
                </template>
            </b-carousel-slide>

            <!-- Geofísica -->
            <b-carousel-slide>
                <template v-slot:img>
                    <responsive-text
                            text="Geofísica"
                            :left="{
                                916: '2%',
                                34: '16%',
                                43: '13%',
                                169: '18%'
                            }"
                            :top="{
                                916: '55%',
                                43: '12%',
                                169: '13%'
                            }"
                            :bottom="{
                                34: 'calc(5.5rem + 2px + 27%)'
                            }"
                            title/>
                    <responsive-text
                            text="Sísmica de Refracción"
                            :left="{
                                916: '0',
                                34: '3%',
                                43: '4%',
                                169: '3%'
                            }"
                            :right="{
                                916: '0'
                            }"
                            :top="{
                                916: '64%',
                                43: '24%',
                                169: '34%'
                            }"
                            :bottom="{
                                34: 'calc(5.5rem + 2px + 18%)'
                            }"
                            delay=".2s"/>
                    <responsive-text
                            text="Resistividad Eléctrica"
                            :left="{
                                916: '0',
                                34: '3%',
                                43: '4%',
                                169: '3%'
                            }"
                            :right="{
                                916: '0'
                            }"
                            :top="{
                                916: '70%',
                                43: '33%',
                                169: '48%'
                            }"
                            :bottom="{
                                34: 'calc(5.5rem + 2px + 10%)'
                            }"
                            delay=".4s"/>
                    <responsive-img
                            alt="Geofísica"
                            img="geofisica"
                            lazy></responsive-img>
                </template>
            </b-carousel-slide>

            <!-- Perforaciones -->
            <b-carousel-slide>
                <template v-slot:img>
                    <responsive-text
                            text="Perforaciones"
                            :left="{
                                916: '1%',
                                34: '2%',
                                43: '2%',
                                169: '8%'
                            }"
                            :top="{
                                916: '12%',
                                34: '9%',
                                43: '12%',
                                169: '10%'
                            }"
                            title/>
                    <responsive-text
                            text="Destructivas"
                            :left="{
                                916: '3%',
                                34: '15%',
                                43: '6%',
                                169: '3%'
                            }"
                            :top="{
                                916: '21%',
                                34: '22%',
                                43: '27%',
                                169: '33%'
                            }"
                            delay=".2s"/>
                    <responsive-text
                            text="Diamantinas"
                            :left="{
                                916: '3%',
                                34: '15%',
                                43: '6%',
                                169: '3%'
                            }"
                            :top="{
                                916: '27%',
                                34: '30%',
                                43: '36%',
                                169: '47%'
                            }"
                            delay=".4s"/>
                    <responsive-img
                            alt="Perforaciones"
                            img="perforaciones"
                            lazy></responsive-img>
                </template>
            </b-carousel-slide>

            <!-- Consultoría y construcción -->
            <b-carousel-slide>
                <template v-slot:img>
                    <responsive-text
                            text="Consultoría y<br/>construcción"
                            :left="{
                                916: '5%',
                                34: '4%',
                                43: '5%'
                            }"
                            :right="{
                                169: '4%'
                            }"
                            :top="{
                                916: '13%',
                                34: '10%',
                                43: '8%'
                            }"
                            :bottom="{
                                169: 'calc(5.5rem + 2px + 1%)'
                            }"
                            title
                            delay=".2s"/>
                    <responsive-img
                            alt="Consultoría"
                            img="consultoria"
                            lazy></responsive-img>
                </template>
            </b-carousel-slide>

            <!-- Control de calidad -->
            <b-carousel-slide>
                <template v-slot:img>
                    <responsive-text
                            text="Control<br/>de<br/>calidad"
                            :left="{
                                34: '2%',
                            }"
                            :right="{
                                916: '2%',
                                43: '32%',
                                169: '32%'
                            }"
                            :top="{
                                916: '6%',
                                34: '6%',
                                43: '9%',
                                169: '10%'
                            }"
                            title
                            delay=".2s"/>
                    <responsive-img
                            alt="Control de calidad"
                            img="control_de_calidad"
                            lazy></responsive-img>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
  // Components
  import ResponsiveImg from '@/components/elements/ResponsiveImg'
  import ResponsiveText from '@/components/elements/ResponsiveText'
  import MoreInfo from '@/components/elements/MoreInfo'

  export default {
    name: 'Inicio',

    components: { MoreInfo, ResponsiveText, ResponsiveImg },

    data () {
      return {
        slide: 0
      }
    },

    computed: {
      current () {
        switch (this.slide) {
          case 0:
            return {
              route: '/laboratorio',
              label: 'Estudios de laboratorio'
            }
          case 1:
            return {
              route: '/geofisica',
              label: 'Geofísica'
            }
          case 2:
            return {
              route: '/perforaciones',
              label: 'Perforaciones destructivas y diamantinas'
            }
          case 3:
            return {
              route: '/consultoria',
              label: 'Consultoría y construcción'
            }
          case 4:
            return {
              route: '/control-de-calidad',
              label: 'Control de calidad'
            }
          default:
            return '/laboratorio'
        }
      }
    },

    methods: {
      resetAnimations () {
        Array.from(document.getElementById('carousel').getElementsByClassName('in-viewport')).reverse().forEach(el => el.classList.remove('in-viewport'))
      }
    }
  }
</script>

<style scoped>
    #carousel >>> .carousel-inner,
    .carousel-item {
        height: 100% !important
    }

    #carousel >>> .carousel-control-prev,
    #carousel >>> .carousel-control-next {
        width: 4%
    }

    #carousel >>> .text-group {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }

    .carousel-item >>> .text-group {
        display: none
    }

    .carousel-item.active >>> .text-group {
        display: block
    }
</style>