var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"img":"consultoria","alt":"Consultoría","itemscope":"","itemtype":"http://schema.org/Service"}},[_c('meta',{attrs:{"itemprop":"serviceType","content":"Consultoría y construcción"}}),_c('link',{attrs:{"itemprop":"provider","href":"#estsuelcon"}}),_c('responsive-text',{attrs:{"text":"Consultoría y<br/>construcción","right":{
                916: '2%',
                34: '2%',
                43: '4%',
                169: '4%'
            },"bottom":{
                916: '4%',
                34: '4%',
                43: '4%',
                169: '4%'
            },"title":""}}),_c('content-row',{attrs:{"title":"Consultoría técnica","img":"consultoria2","alt":"Consultoría","right":""}},[_c('p',[_vm._v("Estudios Geológicos Geotécnicos.")]),_c('p',[_vm._v("Diseños arquitectónicos, estructurales, hidráulicos, etc.")]),_c('p',[_vm._v("Estudios de estabilidad de taludes.")]),_c('p',[_vm._v("Evaluación y métodos de explotación de canteras.")]),_c('p',[_vm._v("Diseño de presas de relave, tierra y enrocado.")]),_c('p',[_vm._v("Diseño de obras menores.")]),_c('p',[_vm._v("Diseños integrales de carreteras.")]),_c('p',[_vm._v("Fiscalización de Proyectos.")]),_c('p',[_vm._v("Otros.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }