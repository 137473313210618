var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('more-info',{attrs:{"current":_vm.current}}),_c('b-carousel',{staticClass:"h-100",attrs:{"id":"carousel","interval":6000,"controls":"","no-hover-pause":""},on:{"sliding-end":_vm.resetAnimations},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},[_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('responsive-text',{attrs:{"text":"Laboratorio","right":{
                            916: '1%',
                            34: '14%',
                            43: '1%',
                            169: '3%'
                        },"top":{
                            916: '55%',
                            34: '28%',
                            43: '33%',
                            169: '32%'
                        },"title":""}}),_c('responsive-text',{attrs:{"text":"Suelos y Materiales","right":{
                            916: '3%',
                            34: '4%',
                            43: '3%',
                            169: '6%'
                        },"top":{
                            916: '63%',
                            34: '40%',
                            43: '44%',
                            169: '52%'
                        },"delay":".2s"}}),_c('responsive-img',{attrs:{"alt":"Laboratorio","img":"laboratorio"}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('responsive-text',{attrs:{"text":"Geofísica","left":{
                            916: '2%',
                            34: '16%',
                            43: '13%',
                            169: '18%'
                        },"top":{
                            916: '55%',
                            43: '12%',
                            169: '13%'
                        },"bottom":{
                            34: 'calc(5.5rem + 2px + 27%)'
                        },"title":""}}),_c('responsive-text',{attrs:{"text":"Sísmica de Refracción","left":{
                            916: '0',
                            34: '3%',
                            43: '4%',
                            169: '3%'
                        },"right":{
                            916: '0'
                        },"top":{
                            916: '64%',
                            43: '24%',
                            169: '34%'
                        },"bottom":{
                            34: 'calc(5.5rem + 2px + 18%)'
                        },"delay":".2s"}}),_c('responsive-text',{attrs:{"text":"Resistividad Eléctrica","left":{
                            916: '0',
                            34: '3%',
                            43: '4%',
                            169: '3%'
                        },"right":{
                            916: '0'
                        },"top":{
                            916: '70%',
                            43: '33%',
                            169: '48%'
                        },"bottom":{
                            34: 'calc(5.5rem + 2px + 10%)'
                        },"delay":".4s"}}),_c('responsive-img',{attrs:{"alt":"Geofísica","img":"geofisica","lazy":""}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('responsive-text',{attrs:{"text":"Perforaciones","left":{
                            916: '1%',
                            34: '2%',
                            43: '2%',
                            169: '8%'
                        },"top":{
                            916: '12%',
                            34: '9%',
                            43: '12%',
                            169: '10%'
                        },"title":""}}),_c('responsive-text',{attrs:{"text":"Destructivas","left":{
                            916: '3%',
                            34: '15%',
                            43: '6%',
                            169: '3%'
                        },"top":{
                            916: '21%',
                            34: '22%',
                            43: '27%',
                            169: '33%'
                        },"delay":".2s"}}),_c('responsive-text',{attrs:{"text":"Diamantinas","left":{
                            916: '3%',
                            34: '15%',
                            43: '6%',
                            169: '3%'
                        },"top":{
                            916: '27%',
                            34: '30%',
                            43: '36%',
                            169: '47%'
                        },"delay":".4s"}}),_c('responsive-img',{attrs:{"alt":"Perforaciones","img":"perforaciones","lazy":""}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('responsive-text',{attrs:{"text":"Consultoría y<br/>construcción","left":{
                            916: '5%',
                            34: '4%',
                            43: '5%'
                        },"right":{
                            169: '4%'
                        },"top":{
                            916: '13%',
                            34: '10%',
                            43: '8%'
                        },"bottom":{
                            169: 'calc(5.5rem + 2px + 1%)'
                        },"title":"","delay":".2s"}}),_c('responsive-img',{attrs:{"alt":"Consultoría","img":"consultoria","lazy":""}})]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('responsive-text',{attrs:{"text":"Control<br/>de<br/>calidad","left":{
                            34: '2%',
                        },"right":{
                            916: '2%',
                            43: '32%',
                            169: '32%'
                        },"top":{
                            916: '6%',
                            34: '6%',
                            43: '9%',
                            169: '10%'
                        },"title":"","delay":".2s"}}),_c('responsive-img',{attrs:{"alt":"Control de calidad","img":"control_de_calidad","lazy":""}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }